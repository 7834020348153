import {
  ApolloError,
  gql,
  MutationHookOptions,
  useMutation,
} from "@apollo/client";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { GET_TICKETS } from "hooks/useTickets/useTickets";

type UpdateTicketData = {
  response: { id: string };
};

type UpdateTicketVars = {
  id: string;
  assignedToId: string;
  status: string;
  title: string;
  details: string | object;
};

const UPDATE_TICKET = gql`
  mutation UpdateManagerTask(
    $id: uuid!
    $assignedToId: uuid
    $status: manager_tasks_status_enum!
    $title: String!
    $details: jsonb!
  ) {
    response: update_manager_tasks_tasks_by_pk(
      pk_columns: { id: $id }
      _set: {
        assigned_to: $assignedToId
        status: $status
        title: $title
        details: $details
      }
    ) {
      id
    }
  }
`;

export const useUpdateTicket = (
  options?: MutationHookOptions<UpdateTicketData, UpdateTicketVars>
) => {
  const [updateTicketMutation, data] = useMutation<
    UpdateTicketData,
    UpdateTicketVars
  >(UPDATE_TICKET, { refetchQueries: [{ query: GET_TICKETS }], ...options });

  const updateTicket = useCallback(
    async (values: UpdateTicketVars) => {
      try {
        await updateTicketMutation({ variables: values });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [updateTicketMutation]
  );

  return { updateTicket, ...data };
};
