import { gql, QueryHookOptions, QueryResult, useQuery } from "@apollo/client";
import { useMemo } from "react";
import { Manager } from "types/general";

type GetManagersData = {
  managers: Pick<Manager, "id" | "firstName" | "lastName">[];
};

type HookType = QueryResult<GetManagersData> & {
  data: GetManagersData;
};

const GET_MANAGERS = gql`
  query getManagers {
    managers {
      id
      firstName: first_name
      lastName: last_name
    }
  }
`;

export const useManagers = (
  options?: QueryHookOptions<GetManagersData>
): HookType => {
  const { data, ...rest } = useQuery<GetManagersData>(
    GET_MANAGERS,
    options
  );

  const parsedData = useMemo<GetManagersData>(
    () => ({
      managers: data?.managers ?? [],
    }),
    [data]
  );

  return {
    data: parsedData,
    ...rest,
  };
};
