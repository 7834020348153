import { SortDirection, Table, flexRender } from "@tanstack/react-table";
import { Loading } from "components";
import React from "react";
import { Arrow3Icon } from "resources/icons";
import styles from "./DesktopTable.styles";
import { TableProps } from "./Table";
import { EmptyState, Filters, Pagination } from "./components";
import { TableData } from "./types";
import { Chip, IconButton } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { TableRowClickType } from "./hooks";

export type DesktopTableProps<D extends TableData> = {
  table: Table<D>;
  disablePagination?: boolean;
  disableFilters?: boolean;
  handleOnGroupingRowClick?: TableRowClickType<D>;
} & Pick<
  TableProps<D>,
  | "columnMultiFilters"
  | "components"
  | "handleOnRowClick"
  | "searchProps"
  | "columnAdditionalFilters"
  | "name"
  | "loading"
>;

export const DesktopTable = <D extends TableData>({
  table,
  columnMultiFilters,
  columnAdditionalFilters,
  components,
  handleOnRowClick,
  handleOnGroupingRowClick,
  searchProps,
  name,
  loading,
  disablePagination = false,
  disableFilters = false,
}: DesktopTableProps<D>) => {
  const rows = table.getRowModel().rows;

  return (
    <>
      <div className={styles.tableContainer}>
        {!disableFilters && (
          <Filters<D>
            tableName={name}
            table={table}
            columnMultiFilters={columnMultiFilters}
            columnAdditionalFilters={columnAdditionalFilters}
            components={components}
            searchProps={searchProps}
          />
        )}

        {loading ? (
          <div className="px-3 py-32">
            <Loading />
          </div>
        ) : (
          <div className="overflow-auto">
            <table className={styles.table}>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className={styles.headRow}>
                    {headerGroup.headers.map((header) => {
                      const isSortable = header.column.getCanSort();
                      const sortDirection = header.column.getIsSorted();
                      const classNameHeader =
                        header.column.columnDef.className?.header ?? "";
                      return (
                        <th
                          key={header.id}
                          scope={header.colSpan > 1 ? "colgroup" : "col"}
                          colSpan={header.colSpan}
                          className={`${styles.headCell} ${classNameHeader}`}
                        >
                          {header.isPlaceholder ? null : (
                            <div
                              {...{
                                className: `${styles.headCellContent} ${
                                  isSortable ? "cursor-pointer select-none" : ""
                                }`,
                                onClick: isSortable
                                  ? header.column.getToggleSortingHandler()
                                  : undefined,
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {sortDirection
                                ? {
                                    asc: (
                                      <Arrow3Icon className={styles.sortIcon} />
                                    ),
                                    desc: (
                                      <Arrow3Icon
                                        className={`${styles.sortIcon} rotate-180`}
                                      />
                                    ),
                                  }[sortDirection as SortDirection]
                                : null}
                            </div>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {rows?.length ? (
                  <>
                    {rows.map((row) => {
                      if (row.getIsGrouped()) {
                        const groupingCell = row
                          .getAllCells()
                          .find(
                            (cell) => cell.column.id === row.groupingColumnId
                          );
                        return (
                          <tr
                            key={row.id}
                            className={`${styles.bodyRow} ${
                              handleOnGroupingRowClick
                                ? "cursor-pointer hover:bg-purple-6"
                                : ""
                            }`}
                            onClick={() => {
                              handleOnGroupingRowClick?.(row);
                            }}
                          >
                            <td
                              className="px-2 py-1"
                              colSpan={row.getVisibleCells().length}
                            >
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  row.getToggleExpandedHandler()?.();
                                }}
                                className="mr-2"
                              >
                                {row.getIsExpanded() ? (
                                  <KeyboardArrowDown />
                                ) : (
                                  <KeyboardArrowRight />
                                )}
                              </IconButton>
                              <span className="font-semibold">
                                {flexRender(
                                  groupingCell!.column.columnDef.cell,
                                  groupingCell!.getContext()
                                )}
                              </span>
                              <Chip
                                size="small"
                                className="ml-2.5 h-5 px-1 text-xs"
                                label={groupingCell?.row.subRows.length}
                              />
                            </td>
                          </tr>
                        );
                      }

                      return (
                        <tr
                          key={row.id}
                          className={`${styles.bodyRow} ${
                            handleOnRowClick
                              ? "cursor-pointer hover:bg-purple-6"
                              : ""
                          }`}
                          onClick={() => {
                            handleOnRowClick?.(row);
                          }}
                        >
                          {row.getVisibleCells().map((cell) => {
                            const cellStyles =
                              cell.column.columnDef.meta?.cellStyles?.(
                                cell,
                                false
                              );
                            const cellClasses =
                              cell.column.columnDef.meta?.cellClasses?.(
                                cell,
                                false
                              );
                            return (
                              <td
                                key={cell.id}
                                className={cellClasses ?? styles.bodyCell}
                                style={cellStyles}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <tr className={styles.bodyRow}>
                    <td colSpan={table.getFlatHeaders()?.length}>
                      <EmptyState />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {!disablePagination &&
        !loading &&
        !!table.getFilteredRowModel().rows.length && (
          <Pagination<D> table={table} />
        )}
    </>
  );
};
